@import '../../global.sass'
  
$height: 70px
$padding-y: 20px

.header__flag
  display: flex
  gap: 5px
  img 
    width: 35px
    cursor: pointer
    display: flex
    justify-content: center
    align-items: center
    position: relative

.header__language-flags 
  display: flex
  align-items: center
  gap: 20px

.header__flag__text
  z-index: 5
  color: white
  font-weight: bold
  text-shadow: 1px 1px 1px black
  user-select: none


.header
  @extend .padding-x
  width: 100%
  display: flex
  position: fixed
  top: 0
  left: 0
  padding: $padding-y inherit
  z-index: 999999
  justify-content: space-between
  height: $height
  align-items: center
  transition-duration: 200ms
  &--not-on-top
    background: $secondary
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2)
  .logo
    height: 45px
  .svg
    transform-box: fill-box
    transform-origin: center
    margin-top: 7px
    fill: white
    .svg__rect
      transition: 200ms
      &:nth-child(1)
        transform: translateY(12px)
      &:nth-child(2)
        transform: translateY(25px)
  .svg--active
    transform-box: fill-box
    transform-origin: center
    .svg__rect
      transition: 200ms
      &:nth-child(1)
        transform: translateX(9px) rotate(45deg)
      &:nth-child(2)
        opacity: 0
      &:nth-child(3)
        transform: translateX(6px)  translateY(28px) rotate(-45deg)
  a
    color: white
    text-decoration: none
  h1
    font-size: 24px
  nav
    display: flex
    gap: 30px
    a
      color: white
      text-decoration: none
      letter-spacing: -0.3px
      font-size: 16px
      &::after
        content: ''
        display: block
        width: 0
        height: 2px
        background: $primary
        transition: 200ms
      &:hover::after
        width: 100%
    @media screen and (max-width: $media-medium)
      a
        font-size: 28px
      gap: 50px
      align-items: center
      justify-content: center
      position: fixed
      right: 0
      top: $height
      flex-direction: column
      height: calc(100vh - $height)
      width: 100%
      background: $primary
      padding: 30px
      transition-duration: 150ms
      transform: translateX(100vw)
  @media screen and (max-width: $media-medium)
    .expanded
      transform: translateX(0)
  .header__logo
    height: calc($height - $padding-y * 2)
  .header__mobile-nav-button
    cursor: pointer
    user-select: none
    @media screen and (min-width: $media-medium)
      display: none
    