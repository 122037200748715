@import '../../global.sass'

.footer
  @extend .padding-x
  padding-top: 30px
  padding-bottom: 30px
  display: grid
  grid-template-columns: 1fr 1fr
  gap: 30px
  @media screen and (max-width: $media-small)
    grid-template-columns: 1fr
  width: 100%
  background: $primary
  color: white
  .logo
    height: 40px
  p
    display: flex
    align-items: center
    gap: 10px
  a
    color: white
    text-decoration: none
    &:hover
      text-decoration: underline
  div:nth-child(1)
    display: flex
    flex-direction: column
    align-items: flex-start
    @media screen and (max-width: $media-small)
      align-items: center
    p
      margin: 8px
  div:nth-child(2)
    display: flex
    flex-direction: column
    align-items: flex-end
    @media screen and (max-width: $media-small)
      align-items: center
    p
      margin: 8px
  
