@import '../../global.sass'
  
.landing
  @extend .padding-x
  background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 30%, rgba(0,0,0,0) 100%), url("/assets/images/background.webp") left center no-repeat
  background-size: cover
  height: 100vh
  display: flex
  align-items: flex-end
  justify-content: center
  flex-direction: column
  position: relative
  color: white
  @media screen and (max-width: $media-normal)
    background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.1) 100%), url("/assets/images/background.webp") left center no-repeat
  &__container
    display: flex
    position: absolute
    top: 25vh
    align-items: flex-end
    flex-direction: column
    gap: 30px
    @media screen and (max-width: $media-normal)
      width: 100%
      align-items: center
      position: static
    h1
      @extend .heading1
      text-align: right
      margin: 0
      @media screen and (max-width: $media-medium)
        text-align: center
    .button-set
      background-color: $secondary
      &__button
        padding: 10px 40px
        @media screen and ( max-width: $media-small )
          padding: 10px 30px
        border: none
        outline: none
        background: transparent
        color: white
        cursor: pointer
        &:hover
          background-color: lighten($secondary, 10%)
      &__button.active
        margin-bottom: -5px
        border-bottom: 5px solid $primary
    p
      font-size: 24px
      text-align: right
      margin: 0
      max-width: 66%
      height: 200px
      @media screen and (max-width: $media-medium)
        text-align: center
        font-size: 18px
