@import '../../global.sass'

.heading__container
  width: 100%
  display: flex
  &--left
    justify-content: flex-start
  &--right
    justify-content: flex-end

.heading
  width: 100%
  min-width: 300px
  max-width: 800px
  &--left
    border-left: 10px solid $primary
    padding-left: 20px
  &--right
    border-right: 10px solid $primary
    padding-right: 20px
    text-align: right
  h2
    @extend .heading2
  p
    @extend .p