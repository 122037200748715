@import '../../global.sass'

.offer-card
  background: #D9D9D9
  padding: 30px
  display: grid
  align-content: flex-start
  gap: 30px
  img
    filter: invert(26%) sepia(58%) saturate(2260%) hue-rotate(190deg) brightness(99%) contrast(106%)
    width: 36px
  div
    display: flex
    justify-content: flex-start
    align-items: flex-start
    gap: 20px
    align-items: center
  h3
    margin: 0
  p
    @extend .p
    margin: 0