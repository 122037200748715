@import '../../global.sass'

.nav
  display: grid
  position: relative
  background: $secondary
  width: 100%
  color: white
  @media screen and (min-width: $media-medium)
    grid-template-columns: repeat(3, 1fr)
  @media screen and (max-width: $media-medium)
    grid-template-columns: repeat(1, 1fr)
    gap: 5px
  &__indicator
    position: absolute
    background: $primary
    height: 55px
    @media screen and (min-width: $media-medium)
      width: calc(100% / 3)
      transition: 300ms
    @media screen and (max-width: $media-medium)
      width: 100%
      z-index: 1
    &--hover
      @extend .nav__indicator
      background: transparent
  &__option
    display: flex
    align-items: center
    justify-content: center
    z-index: 2
    transition-duration: 50ms
    height: 55px
    padding: 15px
    width: 100%
    cursor: pointer
    text-decoration: none
    color: inherit
    &--active
      @extend .nav__option
      color: inherit

.sliding-menu__content
  background: #D9D9D9
  padding: 30px
  h2, p
    margin: 0
